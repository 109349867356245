<template>
	<app-article title="Подбор размеров шпонки и шпоночного паза по диаметру вала">
		<app-calc-container>
			<app-property name="Диаметр вала, мм">
				<input type="text" v-model="input.d">
			</app-property>
			<app-property>
				<button @click="calc()">Подобрать</button>
			</app-property>
			<app-property name="Ширина шпонки, мм">
				<input class="result" type="text" v-model="output.B" readonly>
			</app-property>
			<app-property name="Высота шпонки, мм">
				<input class="result" type="text" v-model="output.h" readonly>
			</app-property>
			<app-property name="Глубина паза вала, мм">
				<input class="result" type="text" v-model="output.t1" readonly>
			</app-property>
			<app-property name="Глубина паза ступицы, мм">
				<input class="result" type="text" v-model="output.t2" readonly>
			</app-property>
			<app-property name="Название">
				<input class="result" type="text" v-model="output.title" readonly>
			</app-property>
		</app-calc-container>
	</app-article>
</template>
<script>

export default {
	components: {

	},
	data() {
		return {
			input: {
				d: undefined,
			},
			output: {
				B: undefined,
				h: undefined,
				t1: undefined,
				t2: undefined,
				title: undefined
			},
			description: '',
		}
	},

	methods: {
		calc() {
			this.output = {
				B: undefined,
					h: undefined,
					t1: undefined,
					t2: undefined,
					title: undefined
			}

			this.$api.getShponka(this.input, (data) => {
				this.output = data.data
			})
		}
	}
}
</script>

<style scoped>
</style>