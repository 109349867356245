<template>
    <div class="slide_container" :class="{ hidden: index != cur_index }" :title="title || 'Атрибут title не указан'">
        <div v-if="!index">Атрибут index не указан</div>
        <div v-if="!cur_index">Атрибут cur_index не указан</div>
        <div class="slide_content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppSlide",
    props: ["index", "cur_index", "title"],
};
</script>

<style scoped>
.slide_container {
    flex-grow: 1;
    flex-shrink: 1;

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 337px;
}

.slide_content {
    flex-grow: 1;

    padding: 5px;
    display: flex;
    flex-direction: column;
}

.hidden {
    display: none;
}
</style>
