<template>
	<app-article title="Подбор стопорного кольца">
		<app-calc-container>
			<app-property name="Тип кольца">
				<select  v-model="input.type">
					<option disabled value="undefined" style="display: none">Выберите значение</option>
					<option v-for="val in types" :value="val.id">{{ val.title }}</option>
				</select>
			</app-property>
			<app-property name="Стандарт">
				<select  v-model="input.standart">
					<option disabled value="undefined" style="display: none">Выберите значение</option>
					<option v-for="val in standarts" :value="val.id">{{ val.title }}</option>
				</select>
			</app-property>
			<app-property name="Условный диаметр вала, мм">
				<select  v-model="input.d" :disabled="diametersFiltred.length === 0">
					<option disabled value="undefined" style="display: none">Выберите значение</option>
					<option v-for="val in diametersFiltred" :value="val">{{ val }}</option>
				</select>
			</app-property>
			<app-property>
				<button @click="calc()">Подобрать</button>
			</app-property>
			<app-property name="Кольцо">
				<app-result-field>{{output.ring}}</app-result-field>
			</app-property>

			<app-property name="Диаметр канавки на валу, мм">
				<app-result-field>{{output.d1}}</app-result-field>
			</app-property>

			<app-property name="Ширина канавки на валу, мм">
				<app-result-field>{{output.m}}</app-result-field>
			</app-property>
			<app-property name="Название">
				<app-result-field>{{output.title}}</app-result-field>
			</app-property>

		</app-calc-container>
		<app-paragraph title="Чертеж" width="900px">
			<app-image :src="$api.getStatic('/img/draw/ring.jpg')" alt=""/>
		</app-paragraph>
	</app-article>
</template>
<script>

export default {
	components: {

	},
	data() {
		return {
			input: {
				d: undefined,
				type: undefined,
				standart: undefined
			},
			output: {
				ring: undefined,
				d1: undefined,
				m: undefined,
				title: undefined
			},
			types: [
				{
					id: 'inner',
					title: 'Внутреннее'
				},
				{
					id: 'outer',
					title: 'Наружное'
				},
			],
			standarts: [
				{
					id: 'din',
					title: 'DIN'
				},
				{
					id: 'gost',
					title: 'ГОСТ'
				},
			],
			diameters: {
				'outer' : [
					4,
					5,
					6,
					7,
					8,
					9,
					10,
					11,
					12,
					13,
					14,
					15,
					16,
					17,
					18,
					19,
					20,
					22,
					23,
					24,
					25,
					26,
					28,
					29,
					30,
					32,
					34,
					35,
					36,
					37,
					38,
					40,
					42,
					45,
					46,
					48,
					50,
					52,
					54,
					55,
					56,
					58,
					60,
					62,
					65,
					68,
					70,
					72,
					75,
					78,
					80,
					82,
					85,
					88,
					90,
					92,
					95,
					98,
					100,
					102,
					105,
					108,
					110,
					112,
					115,
					120,
					125,
					130,
					135,
					140,
					145,
					150,
					155,
					160,
					165,
					170,
					175,
					180,
					185,
					190,
					200,
				],
				'inner': [
					8,
					9,
					10,
					11,
					12,
					13,
					14,
					15,
					16,
					17,
					18,
					19,
					20,
					21,
					22,
					23,
					24,
					25,
					26,
					28,
					29,
					30,
					32,
					34,
					35,
					36,
					37,
					38,
					40,
					42,
					45,
					46,
					47,
					48,
					50,
					52,
					54,
					55,
					56,
					58,
					60,
					62,
					65,
					68,
					70,
					72,
					75,
					78,
					80,
					82,
					85,
					88,
					90,
					92,
					95,
					98,
					100,
					102,
					105,
					108,
					110,
					112,
					115,
					120,
					125,
					130,
					135,
					140,
					145,
					150,
					155,
					160,
					165,
					170,
					175,
					180,
					185,
					190,
					195,
					200,
					210,
					215,
					220,
					225,
					230,
					240,
					250,
					260,
					270,
					280,
					290,
					300,
					310,
					320,
				]
			}
		}
	},

	computed: {
		diametersFiltred() {
			if (!this.input.type) return []
			return this.diameters[this.input.type]
		}
	},
	watch: {
		'input.type': function (val, oldVal) {
			if (oldVal === val) return
			this.input.d = undefined
		}
	},
	methods: {
		calc() {
			this.output =  {
				ring: undefined,
				d1: undefined,
				m: undefined,
				title: undefined
			}

			this.$api.getRing(this.input, (response) => {
				this.output = response.data
			})
		}
	}
}
</script>

<style scoped>
</style>