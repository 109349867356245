<template>
	<app-container style="display:flex;flex-direction: column;overflow: hidden">
		<header>
			<h1>Инструменты для конструкторских расчётов</h1>
		</header>
		<div class="ddd">
			<nav>
				<div v-for="route of routes">
					<router-link  v-if="route.sidebarVisible || route.sidebarVisible === undefined" class="route" :to="route.path">{{route.menuTitle}}</router-link>
				</div>
			</nav>
			<main>
				<div class="alert" v-if="$store.state.alert.text" :style="'background:'+ $store.state.alert.color">
					{{$store.state.alert.text}}
				</div>
				<router-view></router-view>
			</main>
		</div>
	</app-container>
</template>
<script>

import {routes} from "@/routes";
import AppContainer from "./components/ui/AppContainer.vue";
import AppContainerScroll from "./components/ui/AppContainerScroll.vue";

	export default {
		components: {
			AppContainerScroll,
			AppContainer

		},
		data() {
			return {
				routes
			}
		},

		beforeMount() {
			this.$api.baseURL = process.env.VUE_APP_API_URL
			this.$api.onError = (response) => {
				if(process.env.NODE_ENV === 'development') console.error(response)

				this.$alert(response.data || response.statusText)
			}
		}
	}
</script>
<style>
	html, body, #app {
		font-family: Tahoma,Verdana,Segoe,sans-serif;
		font-family: 'GOST type A', sans-serif;
		font-style: italic;
		font-size: 16pt;
		color: #181818;
		letter-spacing: 0.05rem;
	}

	header {
		border-bottom:1px solid lightgray;
		padding:10px;
		background: white;
		overflow: hidden;
		flex-shrink: 0;
		text-align: left;
		background: rgba(0, 0, 0, 0.07);
		display: none;
	}

	.header {
		font-size: 16pt;
		font-weight: bold;
		text-transform: uppercase;
		text-shadow: 0 0 15px gray;
		margin: 5px;
		border-radius: 5px;
		border:1px solid lightgray;
		padding: 5px;
	}

	h1 {
		padding: 15px;
	}

	h1, h2, h3 {
		font-family: inherit;
		font-weight: bold;
	}

	.ddd {
		margin: 0;
		width: 100%;
		flex-grow: 1;
		flex-shrink: 1;

		display: flex;
		flex-direction: row;
		overflow: hidden;
	}

	nav {
		width:320px;
		text-align: left;
		box-shadow: 0 1px 4px 0 black;
		z-index: 2;
		background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 0, white 800px), url('@/assets/blueprint.jpg');
		flex-shrink: 0;
	}

	.route {
		margin: 5px;
		padding: 5px;
		background: rgba(0, 0, 0, 0.05);
		border: 1px solid lightgray;
		border-radius: 5px;
		cursor: pointer;
		display: block;
		color: black;
		text-decoration: none;
	}

	.route:hover {
		border-color: grey;
	}

	main {
		display: block;
		width: 100%;
		height: auto;
		overflow-y: auto;
		//background: rgba(0, 0, 0, 0.07);
		position: relative;
		background: white;
	}

	.alert {
		position: absolute;
		top: 15px;
		right: 15px;
		background: pink;
		padding: 15px 30px;
		border-radius: 5px;
		box-shadow: 0 0 5px 0 black;
	}

	@media (max-width: 640px) {
		* {
			//background: red !important;
		}

		.ddd {
			flex-direction: column;
			background: white;
			overflow: auto;
		}

		nav, main {
			width: auto;
			margin: 0px;
			border:none;
		}

		nav {
			box-shadow: none;
			background: none;
		}

		main {
			flex-grow: 1;
			flex-shrink: 0;
		};
	}
</style>