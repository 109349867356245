<template>
	<app-paragraph class="calc-container" width="900px">
		<slot></slot>
	</app-paragraph>

</template>

<script>
export default {
    name: "AppCalcContainer",
    props: {
		title: { default: ''},
	},
};
</script>

<style scoped>
	.calc-container {
		//background: #eeecec;
		border-radius: 5px;
		padding: 5px;
		background: rgba(0, 0, 0, 0.03);
		box-shadow: 0 0 1px 0 black;
	}
</style>
