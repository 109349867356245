<template>
	<app-article title="Расчет развертки витка цилиндрического шнека">
		<app-calc-container>
			<app-property name="Внешний диаметр шнека (D), мм">
				<input type="text" v-model="input.D">
			</app-property>
			<app-property name="Внутренний диаметр шнека (d), мм">
				<input type="text" v-model="input.d">
			</app-property>
			<app-property name="Шаг пера шнека (p), мм">
				<input type="text" v-model="input.p">
			</app-property>
			<app-property>
				<button @click="calc()">Подобрать</button>
			</app-property>
			<app-property name="Наружный диаметр плоской развертки, мм">
				<input class="result" type="text" v-model="output.Dp" readonly>
			</app-property>
			<app-property name="Внутренний диаметр плоской развертки, мм">
				<input class="result" type="text" v-model="output.dp" readonly>
			</app-property>
			<app-property name="Угол выреза в развертке, град">
				<input class="result" type="text" v-model="output.bp" readonly>
			</app-property>
			<app-property name="Размер выреза по внутреннему диаметру, мм">
				<input class="result" type="text" v-model="output.b" readonly>
			</app-property>
			<app-property name="Размер выреза по наружному диаметру, мм">
				<input class="result" type="text" v-model="output.B" readonly>
			</app-property>
		</app-calc-container>
	</app-article>
</template>
<script>

export default {
	components: {

	},
	data() {
		return {
			placeholder: {
				a: 'Введите значение в пределах 0 - 49'
			},
			input: {
				D: undefined,
				d: undefined,
				p: undefined,
			},
			output: {
				Dp: undefined,
				dp: undefined,
				bp: undefined,
				b: undefined,
				B: undefined,
			},
		}
	},

	methods: {
		calc() {
			this.output = {
				Q: undefined,
				V: undefined
			}

			this.$api.getScrew2(this.input, (response) => {
				this.output = response.data
			})
		}
	}
}
</script>

<style scoped>
</style>