import { createStore } from "vuex";

export default createStore({
    strict: false,
    state: {
        alert: {
            text: '',
            color: 'pink'
        },
    }
})