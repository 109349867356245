<template>
	<div class="property-container">
		<slot></slot>
	</div>
</template>

<script>
export default {
    name: "AppPropertyContainer",
};
</script>

<style scoped>
.property-container {
	display: flex;
	align-content: center;
	flex-direction: row;
	justify-content: space-between;
	margin: 10px 5px;
}
</style>
