<template>
	<div class="property-name">
		<slot></slot>
	</div>
</template>

<script>
export default {
    name: "AppPropertyName",
};
</script>

<style scoped>
.property-name {
	margin: auto 5px auto 0  !important;
	width: 45%;
	text-align: left !important;
	flex-shrink: 0;
	font-size: 14pt;
	text-align: right !important;
}
</style>
