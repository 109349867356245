<template>
	<div class="property-value">
		<slot></slot>
	</div>
</template>

<script>
export default {
    name: "AppPropertyValue",
};
</script>

<style scoped>
.property-value {
	width: 50% !important;
	margin-right: 0 !important;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	font-size: 14pt;
}
</style>
