<template>
 <div class="app-container">
   <slot/>
 </div>
</template>

<script>
export default {
  name: "AppContainer"
}
</script>

<style>
html, body, #app {
	margin: 0;
	padding: 0;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	text-align: center;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: lightgray;
	//border:1px solid red
}

.app-container {
	width: 100%;
 	height: 100%;
	overflow: auto;
}

div.result

input[type='text'],
select,
img,
div.result
{
	margin: 5px;
	width: auto;
	flex-shrink: 1;
}

input[type='text'],
select,
img,
div.result
{
	border: 1px solid #a2a0a0;
	border-radius: 5px;
	background: white;
}

input[type='text'],
select,
div.result
{
	padding: 5px;
	flex-grow: 1;
	box-sizing: border-box;
	//min-height: 17px;
}

input[type='text'] {
	padding: 4px 10px;
	height: 21px !important;
}

select {
	margin: 0;
}

button {
	padding: 10px;
	width: auto;
	border: 1px solid #a2a0a0;
	border-radius: 5px;
}

select, button {
	cursor: pointer;
}

.border {
	border: 1px solid #a2a0a0;
	border-radius: 5px;
}

div.result {
	background: white;
	min-height: 19px;
	padding: 5px;
	border-radius: 5px;
	margin: 0;
	text-align: left;
	line-height: 1;
}

</style>