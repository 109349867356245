<template>
	<div class="paragraph_container" :style="'max-width:' + width">
		<h2 v-if="title">{{title}}</h2>
		<slot></slot>
	</div>
</template>

<script>
export default {
    name: "AppParagraph",
    props: {
		title: { default: ''},
		width:  { default: '100%'}
	},
};
</script>

<style scoped>
.paragraph_container {
    overflow: hidden;
	margin: auto;
}

.paragraph_container > .content {
    display: flex;
    flex-direction: column;
}

.paragraph_container > .content > .title {
    text-align: center;
    color: black;
    margin-bottom: 3px;
}
</style>
