import TheFRC from "./TheFRC.vue";
import TheRing from "./TheRing.vue";
import TheScrew from "./TheScrew.vue";
import TheSectornik from "./TheSectornik.vue";
import TheVP from "./TheVP.vue";
import TheShponka from "./TheShponka.vue";
import TheKRP from "./TheKRP.vue";
import TheScrew2 from "./TheScrew2.vue";

export default {
    TheVP,
    TheShponka,
    TheSectornik,
    TheScrew,
    TheScrew2,
    TheFRC,
    TheKRP,
    TheRing
};