<template>
	<app-article title="Приветствуем!">
		<p>
			Спасибо, что пришли на наш ресурс. Он посвящен конструкторским расчетам и подбору оборудования. Все расчеты и подборы сделаны конструкторами и для конструкторов.
			При реализации функционала мы ориентировались прежде всего на повседневные задачи и прикладное применение, чтобы под рукой всегда был удобный и доступный инструментарий, в котором собрано все необходимое в одном месте.
		</p>
		<p>
			Ниже Вы можете найти перечень доступных расчетов и подборов.
		</p>
		<div class="route-container">
			<div v-for="route of routes">
				<router-link v-if="route.mainpageVisible || route.mainpageVisible === undefined" :to="route.path" class="route">
					<div class="title">{{route.menuTitle}}</div>
					<div class="description">{{route.description}}</div>
				</router-link>
			</div>
		</div>
	</app-article>
</template>
<script>
import {routes} from "../routes";
import AppArticle from "../components/ui/AppArticle.vue";
import AppParagraph from "../components/ui/AppParagraph.vue";

export default {
	components: {
		AppParagraph,
		AppArticle
	},
	data() {
		return {
			routes
		}
	}
}
</script>

<style scoped>
p,
.route-container {
	text-align: left;
	max-width: 1000px;
	margin: 0 auto;
}


.route {
	text-align: left;
	margin: 15px 0 !important;
}

.route>.title {
	font-weight: bold;
}

.route>.description {
	font-style: italic;
	margin-top: 5px;
}

</style>