<template>
	<div class="result">
		<slot></slot>
	</div>
</template>

<script>

export default {
    name: "AppResultField"
};
</script>

<style scoped>

</style>
