<!--<AppButton color="#5D6897" onhover:"#929ED1" @click="$emit('click')">-->
<!--<slot/>-->
<!--</AppButton>-->

<template>
  <button
      :disabled="disabled"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      class="btn_style_color"
      :style="{backgroundColor: hover ? onhover || '#929ED1' : color  }"
      @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "AppButton",
  props:{
    color:String,
    onhover:String,
    disabled:Boolean
  },
  data: function () {
    return {
      hover:false
    }
  }

}
</script>

<style scoped>
.btn_style_color{
  min-width: 100px;
  margin:5px;
  padding:0 12px;
  font-family: 'Inter';
  font-size: 12px;
  border: 0;
  height: 30px;
  white-space: nowrap;
  cursor: pointer;
  color: var(--button-filter-color-text);
}
.btn_style_color:hover{
  color: #FFFFFF ;
}
</style>