<template>
	<div v-for="route of routes2">{{route}}</div>
</template>
<script>

	import {routes} from "@/routes";
	export default {
		mounted() {
			routes.forEach((route) => {
				console.log(`${location.origin}/#${route.path}`)
			})
		},

		computed: {
			routes2() {
				let result = []
				routes.forEach((route) => {
					result.push(`https://mechraschet.ru/app/#${route.path}`)
				})

				return result;
			}
		}
	}
</script>


<style>

</style>