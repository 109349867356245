import axios from "axios";

const API = function () {
    return {
        baseURL: undefined,
        ajaxRequest: null,
        onError: undefined,
        onLogin: undefined,
        onLogout: undefined,

        getStatic(relativePath) {
            return `${this.baseURL}${relativePath}`
        },
        getSectornik(params, success) {
            this.get('/v1/sectornik', params, success)
        },
        getShponka(params, success) {
            this.get('/v1/shponka', params, success)
        },
        getVP(params, success) {
            this.get('/v1/vp', params, success)
        },
        getRing(params, success) {
            this.get('/v1/ring', params, success)
        },

        getFRC(params, success) {
            this.get('/v1/frc', params, success)
        },

        getCrew(params, success) {
            this.get('/v1/screw', params, success)
        },

        getScrew2(params, success) {
            this.get('/v1/screw2', params, success)
        },

        get(url, params, success) {
            this.request({
                method: "get",
                url,
                params,
                callback: (response) => {
                    success(response.data)
                }
            })
        },

        // обертка аксиоса для все хзапросов к api
        request(params) {
            if(process.env.NODE_ENV === 'development') console.log(params)
            axios({
                baseURL: this.baseURL,
                url: params.url,
                method: params.method,
                headers: params.headers,
                data: params.data,
                params: params.params,
                onUploadProgress: params.progress,
            })
                .then((response) => {
                    if(process.env.NODE_ENV === 'development') console.log(response);
                    params.callback(response)
                })
                .catch((error) => {
                    let code = error.response && error.response.status ? error.response.status : '???'
                    if(process.env.NODE_ENV === 'development') console.error(code, params, error)
                    this.onError(error.response)
                });

        },
    }
}

const createAPI = function () {
    return {
        install: function (app) {

            let api = API();

            if (app.config.globalProperties) {
                app.config.globalProperties.$api = api; // объявление для Vue3
            } else {
                app.prototype.$api = api; // объявление для Vue2
            }
        },
    };
};

export {createAPI};