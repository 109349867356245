<template>
	<app-article title="Подбор муфты упругой втулочно-пальцевой">
		<app-calc-container>
			<div class="description">Выберите требуемый крутящий момент из списка, чтобы получить параметры муфты</div>
			<app-property name="Крутящий момент, H*m">
				<select  v-model="input.hm">
					<option disabled value="undefined" style="display: none">Выберите значение</option>
					<option v-for="val in vp">{{ val }}</option>
				</select>
			</app-property>
			<app-property>
				<button @click="calc()">Подобрать</button>
			</app-property>
			<app-property name="Вам подходит муфта со следующими параметрами">
				<app-result-field>{{output.description}}</app-result-field>
			</app-property>
		</app-calc-container>
		<app-paragraph title="Чертеж" width="900px">
			<app-image :src="$api.getStatic('/img/draw/vp.png')" alt=""/>
		</app-paragraph>
	</app-article>
</template>
<script>

export default {
	components: {

	},
	data() {
		return {
			input: {
				hm: undefined
			},
			output: {
				description: undefined
			},
			vp: [
				6.3,
				16,
				31.5,
				63,
				125,
				250,
				500,
				710,
				1000,
				2000,
				4000,
				8000
			]
		}
	},
	methods: {
		calc() {
			this.output = {
				description: undefined
			}

			this.$api.getVP(this.input, (data) => {
				this.output.description = data.string
			})
		}
	}
}
</script>

<style scoped>
</style>