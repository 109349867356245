<template>
    <div class="slider_container">
        <div v-if="!style">Атрибут style не указан</div>
        <div v-if="!modelValue">Атрибут v-model не указан</div>
        <div class="header">
            <div class="num">{{ index }}</div>
            <div class="text ellipsis">{{ get_slide_title(index) }}</div>
        </div>
        <div ref="slider" class="content">
            <slot></slot>
        </div>
        <div class="control" v-if="count > 1">
            <button class="switcher fas fa-angle-left" @click="go_back()" :disabled="index == 1"></button>
            <div class="marker" v-for="value of count" :key="value">
                <button :title2="get_slide_title(value)" @click="go_to(value)" :class="{ selected: value == index }">{{ value }}</button>
            </div>
            <button class="switcher fas fa-angle-right" @click="go_forward()" :disabled="index > count - 1"></button>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppSlider",
    props: ["modelValue", "style"],
    emits: ["update:modelValue"],
    data() {
        return {
            index: 1,
        };
    },

    computed: {
        count() {
            if (this.style == "desktop") return this.get_slide_count() - 1;
            return this.get_slide_count();
        },
    },

    mounted() {
        this.go_to(this.modelValue);
    },

    watch: {
        style(new_value, old_value) {
            if (old_value == "mobile" && new_value == "desktop" && this.index == this.get_slide_count()) {
                this.go_to(this.get_slide_count() - 1);
            }
        },
    },

    methods: {
        get_slide_count() {
            if (!this.$refs.slider) return [];
            if (!this.$refs.slider.children) return [];
            return this.$refs.slider.children.length;
        },

        get_slide_title(index) {
            if (!this.$refs.slider) return "";
            if (!this.$refs.slider.children) return "";
            return this.$refs.slider.children[index - 1].title;
        },

        go_back() {
            if (this.index == 1) return;
            this.go_to(this.index - 1);
        },

        go_forward() {
            if (this.index > this.count - 1) return this.count;
            this.go_to(this.index * 1 + 1);
        },

        go_to(index) {
            this.index = index;
            this.$emit("update:modelValue", this.index);
        },
    },
};
</script>

<style scoped>
.slider_container {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}

.slider_container > .header {
    font-weight: bold;
    padding: 5px;
    background: #fc5a0a;
    margin: 5px 0px;
    margin-bottom: 0px;
    border-radius: 20px;

    display: flex;
    align-content: center;
    justify-content: flex-start;
    position: relative;
    width: auto;
}

.slider_container > .header > .num {
    position: absolute;
    background: white;
    border-radius: 50%;
    padding: 3px 10px;
    font-size: 14pt;
}

.slider_container > .header > .text {
    margin: auto 35px;
    flex-grow: 1;
    text-align: center;
    padding: 5px 0px;
    color: black;
}

.slider_container > .content {
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    overflow-y: auto;
    margin: 5px 0px;
    border: 1px solid lightgray;
}

.slider_container > .control {
    display: flex;
    justify-content: space-between;
}

.slider_container > .control > .switcher {
    padding: 5px;
    font-weight: 900;
    font-size: 24pt;
    color: white;
    background: #fc5a0a;
    border: none;
    border-radius: 50px;
    padding: 0px 8px;
    text-align: center;
    cursor: pointer;
    opacity: 0.9;
}

.slider_container > .control > .switcher:hover {
    opacity: 1;
}

.slider_container > .control > .switcher:disabled {
    background: lightgray;
}

.slider_container > .control > .marker {
    margin: auto;
    display: flex;
    position: relative;
    border: none;
}

.slider_container > .control > .marker > button {
    margin: auto;
    border: none;
    padding: 0px;

    cursor: pointer;
    color: black;
    border: 2px solid lightgrey;
    border-radius: 50%;

    padding: 0px 4px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    z-index: 2;
    background: white;
}

.slider_container > .control > .marker > button:hover {
    color: #fc5a0a;
    border-color: #fc5a0a;
    color: black;
    font-weight: 700;
    border: 2px solid #fc5a0a;
}

.slider_container > .control > .marker > button:hover::after {
    content: attr(title2);
    position: absolute;
    width: 100px;
    padding: 6px 0px;
    top: -41px;
    left: -40px;
    background: white;
    background: #fc5a0a;
    color: white;

    border-radius: 20px;
    box-shadow: 0px 0px 3px 1px gray;

    text-align: center;
    font-size: 8pt;
    font-weight: normal;
}

.slider_container > .control > .marker > .selected {
    border-color: #fc5a0a;
    color: black;
    font-weight: 700;
    border: 2px solid #fc5a0a;
}
</style>
