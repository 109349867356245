<template>
	<app-property-container>
		<app-property-name>{{name}}</app-property-name>
		<app-property-value>
			<slot></slot>
		</app-property-value>
	</app-property-container>
</template>

<script>

export default {
    name: "AppProperty",
	components: {

	},
	props: [
		'name',
		'value'
	]
};
</script>

<style scoped>

</style>
