<template>
  <div class="scroll">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "AppContainerScroll"
}
</script>

<style scoped>
.scroll{
  overflow-y: scroll;
  border: 1px solid #c9c0c0;
}
</style>