<template>
	<app-article title="Клиноременная передача">
		<app-calc-container>
			<div class="description">Выберите требуемые параметры, чтобы подобрать передачу</div>
			<app-property name="Частота вращения малого шкива, об/мин">
				<input type="text" v-model="input.frequency">
			</app-property>
			<app-property name="Расчетный диаметр большого шкива, мм">
				<input type="text" v-model="input.frequency">
			</app-property>
			<app-property>
				<button @click="calc()">Подобрать</button>
			</app-property>
		</app-calc-container>
	</app-article>
</template>
<script>

export default {
	components: {

	},
	data() {
		return {
			input: {
				frequency: undefined
			},
			output: {

			}
		}
	},

	methods: {
		calc() {

		}
	}
}
</script>

<style scoped>

</style>