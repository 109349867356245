<template>
	<app-article title="Подбор шнека">
		<app-calc-container>
			<app-property name="Внешний диаметр шнека (D), мм">
				<input type="text" v-model="input.D">
			</app-property>
			<app-property name="Внутренний диаметр шнека (d), мм">
				<input type="text" v-model="input.d">
			</app-property>
			<app-property name="Шаг пера шнека (p), мм">
				<input type="text" v-model="input.p">
			</app-property>
			<app-property name="Обороты шнека (n), об/мин">
				<input type="text" v-model="input.n">
			</app-property>
			<app-property name="Угол наклона шнека (a), град">
				<input type="text" v-model="input.a" :title="placeholder.a" :placeholder="placeholder.a">
			</app-property>
			<app-property name="Параметры материала (materialK)">
				<select v-model="input.materialK">
					<option disabled value="undefined" style="display: none">Выберите значение</option>
					<option v-for="val in materialType" :value="val.value">{{ val.title }}</option>
				</select>
			</app-property>
			<app-property name="Насыпная плотность материала (W), т/м3">
				<input type="text" v-model="input.W">
			</app-property>
			<app-property>
				<button @click="calc()">Подобрать</button>
			</app-property>
			<app-property name="Производительность шнека (Q), т/ч">
				<input class="result" type="text" v-model="output.Q" readonly>
			</app-property>
			<app-property name="Объемная производительность шнека (V), м3/ч">
				<input class="result" type="text" v-model="output.V" readonly>
			</app-property>
		</app-calc-container>
	</app-article>
</template>
<script>

export default {
	components: {

	},
	data() {
		return {
			placeholder: {
				a: 'Введите значение в пределах 0 - 49'
			},
			input: {
				D: undefined,
				d: undefined,
				p: undefined,
				n: undefined,
				a: undefined,
				materialK: undefined,
				W: undefined
			},
			output: {
				Q: undefined,
				V: undefined
			},
			materialType: [
				{
					id: 1,
					title: 'Тяжелые абразивные',
					'description': '',
					'value': 0.125
				},
				{
					id: 2,
					title: 'Тяжёлая малоабразивная',
					'description': '',
					'value': 0.25
				},
				{
					id: 3,
					title: 'Лёгкие малоабразивные',
					'description': '',
					'value': 0.32
				},
				{
					id: 4,
					title: 'Лёгкие неабразивные',
					'description': '',
					'value': 0.4
				},
			],
			description: '',
		}
	},

	methods: {
		calc() {
			this.output = {
				Q: undefined,
				V: undefined
			}

			this.$api.getCrew(this.input, (response) => {
				this.output = response.data
			})
		}
	}
}
</script>

<style scoped>
</style>