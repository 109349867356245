<template>
	<app-article title="Расчет производительности секторного питателя (дозатора)">
		<app-calc-container>
			<app-property name="Обороты привода питателя, об/мин">
				<input type="text" v-model="input.n">
			</app-property>
			<app-property name="Насыпная плотность материала, т/м3">
				<input type="text" v-model="input.W">
			</app-property>
			<app-property name="Внешний диаметр камеры питателя, мм">
				<input type="text" v-model="input.D">
			</app-property>
			<app-property name="Внешний диаметр ротора питателя, мм">
				<input type="text" v-model="input.d">
			</app-property>
			<app-property name="Ширина камеры питателя, мм">
				<input type="text" v-model="input.L">
			</app-property>
			<app-property name="Коэффициент заполнения камеры">
				<input
					type="text"
					v-model="input.k"
					:title="placeholder.k"
					:placeholder="placeholder.k"
				>
			</app-property>
			<app-property>
				<button @click="calc()">Подобрать</button>
			</app-property>
			<app-property name="Производительность питателя, т/ч">
				<input class="result" type="text" v-model="output.V" readonly>
			</app-property>
			<app-property name="Объемная производительность питателя, м3/ч">
				<input class="result" type="text" v-model="output.Q" readonly>
			</app-property>
		</app-calc-container>
		<app-paragraph title="Чертеж" width="900px">
			<app-image :src="$api.getStatic('/img/draw/sectornik.jpeg')" alt=""/>
		</app-paragraph>
	</app-article>
</template>
<script>

import AppParagraph from "../ui/AppParagraph.vue";

export default {
	components: {
		AppParagraph

	},
	data() {
		return {
			placeholder: {
				k: 'Введите значение в диапазоне 0,8-0,9',
			},
			input: {
				D: undefined,
				d: undefined,
				L: undefined,
				n: undefined,
				k: undefined,
				W: undefined
			},
			output: {
				Q: undefined,
				V: undefined
			}
		}
	},

	methods: {
		calc() {
			this.output = {
				Q: undefined,
				V: undefined
			}

			this.$api.getSectornik(this.input, (response) => {
				this.output = response.data
			})
		}
	}
}
</script>

<style scoped>

</style>