<template>
	<article>
		<h1 v-if="title">{{title}}</h1>
		<slot></slot>
	</article>
</template>

<script>
export default {
    name: "AppArticle",
    props: {
		title: { default: ''},
	},
};
</script>

<style scoped>
	article {
		margin: 15px;
	}
</style>
