import AppButton from "./AppButton.vue";
import AppContainer from "./AppContainer.vue";
import AppContainerScroll from "./AppContainerScroll.vue";
import AppParagraph from "./AppParagraph.vue";
import AppParameter from "./AppParameter.vue";
import AppSlide from "./AppSlide.vue";
import AppSlider from "./AppSlider.vue";
import AppPropertyContainer from "./AppPropertyContainer.vue";
import AppPropertyName from "./AppPropertyName.vue";
import AppPropertyValue from "./AppPropertyValue.vue";
import AppProperty from "./AppProperty.vue";
import AppArticle from "./AppArticle.vue";
import AppImage from "./AppImage.vue";
import AppResultField from "./AppResultField.vue";
import AppCalcContainer from "./AppCalcContainer.vue";



const components = [
    AppImage,
    AppArticle,
    AppButton,
    AppContainer,
    AppContainerScroll,
    AppParagraph,
    AppParameter,
    AppSlide,
    AppSlider,
    AppPropertyContainer,
    AppPropertyName,
    AppPropertyValue,
    AppProperty,
    AppResultField,
    AppCalcContainer
];

export default {
	install(app){
		components.forEach(
			(component) => {
				app.component(component.name,  component)
			}
		);
	}
}