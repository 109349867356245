import calcs from '../components/calcs'
import Home from "../views/Home.vue";
import SiteMap from "@/components/SiteMap.vue";

export const routes = [
    {
        path: "/",
        name: 'Home',
        menuTitle: 'Главная',
        component: Home,
        mainpageVisible: false
    },
    {
        path: '/podbor-mufty-frc',
        name: 'FRC',
        menuTitle: 'Муфта FRC',
        description: ' Подбор на основании исходных данных необходимой кулачковой муфты FRC, с выводом габаритных и присоединительных размеров',
        component: calcs.TheFRC,
    },
    {
        path: '/podbor-mufty-vtulochno-palcevaya',
        name: 'VP',
        menuTitle: 'Муфта втулочно-пальцевая',
        description: 'Подбор по крутящему моменту втулочно пальцевой муфты с выводом габаритных и присоединительных размеров',
        component: calcs.TheVP,
    },
    {
        path: '/raschet-proizvoditelnosti-shneka',
        name: 'Screw',
        menuTitle: 'Шнек',
        description: 'Расчет производительности винтового цилиндрического конвейера (шнека)',
        component: calcs.TheScrew
    },
    {
        path: '/raschet-razvertki-pera-shneka',
        name: 'Screw2',
        menuTitle: 'Шнек (развертка пера)',
        description: 'Расчет геометрических размеров заготовки витка цилиндрического шнека',
        component: calcs.TheScrew2
    },
    {
        path: '/raschet-proizvoditelnost-sektornogo-pitatelya',
        name: 'Sectornik',
        menuTitle: 'Секторный питатель',
        description: 'Расчет производительности секторного питателя (дозатора)',
        component: calcs.TheSectornik
    },
    {
        path: '/podbor-shponki-i-shponochnogo-paza-po-diametru-vala',
        name: 'Shponka',
        menuTitle: 'Шпонка и шпоночный паз',
        description: 'Подбор шпонки и шпоночного паза по диаметру вала',
        component: calcs.TheShponka
    },
    {
        path: '/ring',
        name: 'Ring',
        menuTitle: 'Стопорное кольцо',
        description: 'Подбор по диаметру вала внешнего (внутреннего) стопорного кольца и размеров канавки',
        component: calcs.TheRing
    },
    {
        path: '/sitemap.txt',
        name: 'Sitemap',
        menuTitle: 'Сайтмап',
        description: 'Подбор по диаметру вала внешнего (внутреннего) стопорного кольца и размеров канавки',
        component: SiteMap,
        sidebarVisible: false,
        mainpageVisible: false
    },
];
