<template>
	<img :src="src" :alt="alt" :style="'width:' + width">
</template>

<script>
export default {
    name: "AppImage",
    props: {
		src: { default: ''},
		alt: { default: ''},
		width:  { default: '100%'}
	},
};
</script>

<style scoped>
	img {
		width: 100%;
		margin: 0;
		box-sizing: border-box;
	}
</style>
