<template>
    <div class="parameter_container">
        <div class="title ellipsis" v-html="title"></div>
        <div class="value">{{ value }}</div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "AppParameter",
    props: ["title", "value"],
};
</script>

<style scoped>
.parameter_container {
    margin: 0px;
    margin-top: 2px;
    width: auto;

    display: flex;
    overflow: hidden;
    font-size: 10pt;
}

.parameter_container > .title {
    color: grey;
    margin-right: 2px;
    min-width: 130px;
}

.parameter_container > .title::after {
    content: ":";
}

.parameter_container > .value {
    color: black;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    margin-top: auto;
}
</style>
