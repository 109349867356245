<template>
	<app-article title="Подбор муфты FRC">
		<app-calc-container>
			<div class="description">Выберите требуемые параметры, чтобы подобрать муфту</div>
			<app-property name="Условия эксплуатации">
				<select v-model="input.conditions">
					<option disabled value="undefined" style="display: none">Выберите значение</option>
					<option v-for="val in workConditions" :value="val.id">{{ val.title }}</option>
				</select>
			</app-property>
			<app-property name="Тип привода">
				<select v-model="input.engine">
					<option disabled value="undefined" style="display: none">Выберите значение</option>
					<option v-for="val in engineTypes" :value="val.id">{{ val.title }}</option>
				</select>
			</app-property>
			<app-property name="Количество часов работы в день">
				<select v-model="input.hours">
					<option disabled value="undefined" style="display: none">Выберите значение</option>
					<option v-for="val in workHours" :value="val.id">{{ val.title }}</option>
				</select>
			</app-property>
			<app-property name="Частота вращения">
				<select v-model="input.rotation">
					<option disabled value="undefined" style="display: none">Выберите значение</option>
					<option v-for="val in rotations">{{ val }}</option>
				</select>
			</app-property>
			<app-property name="Номинальная мощность привода, кВт">
				<select v-model="input.power">
					<option disabled value="undefined" style="display: none">Выберите значение</option>
					<option v-for="val in powers">{{ val }}</option>
				</select>
			</app-property>
			<app-property name="Тип полумуфты">
				<select v-model="input.type">
					<option disabled value="undefined" style="display: none">Выберите значение</option>
					<option v-for="val in types" :value="val.id">{{ val.title }}</option>
				</select>
			</app-property>
			<app-property>
				<button @click="calc()">Подобрать</button>
			</app-property>
			<app-property name="Вам подходит муфта со следующими параметрами:">
				<app-result-field>{{output.description}}</app-result-field>
			</app-property>

		</app-calc-container>
		<app-paragraph title="Чертеж" width="900px">
			<app-image :src="$api.getStatic('/img/draw/frc.png')" alt=""/>
		</app-paragraph>
	</app-article>
</template>
<script>

import AppCalcContainer from "../ui/AppCalcContainer.vue";

export default {
	components: {
		AppCalcContainer

	},
	data() {
		return {
			input: {
				conditions: undefined,
				engine: undefined,
				hours: undefined,
				rotation: undefined,
				power: undefined,
				type: undefined
			},
			output: {
				description: undefined
			},

			workConditions: [
				{
					id: 1,
					title: 'Легкие',
					'description': ''
				},
				{
					id: 2,
					title: 'Средние',
					'description': ''
				},
				{
					id: 3,
					title: 'Тяжелые',
					'description': ''
				},
				{
					id: 4,
					title: 'Очень тяжелые',
					'description': ''
				},
			],

			engineTypes: [
				{
					id : 1,
					title :'Электродвигатели и паровые турбины'
				},
				{
					id : 2,
					title :'Двигатели внутреннего сгорания, паровые двигатели и водяные турбины'
				},
			],

			workHours: [
				{
					id: 1,
					title: '<10'
				},
				{
					id: 2,
					title: '10-16'
				},
				{
					id: 3,
					title: '>16'
				},
			],

			rotations: [
				50, 100, 200, 300, 400, 500, 600, 700, 720, 800, 900, 960,
				1000, 1200, 1400, 1440, 1600, 1800, 2000, 2200, 2400, 2600,
				2800, 2880, 3000, 3600
			],

			powers: [
				0.09,
				0.12,
				0.18,
				0.25,
				0.37,
				0.55,
				0.75,
				1.1,
				1.5,
				2.2, 3.0, 4.0, 5.5, 7.5, 11, 15, 18.5, 22, 30, 37, 45, 55, 75, 90, 110,
				132, 160, 200, 250, 315
			],

			types: [
				{
					id: 1,
					title: 'BB'
				},
				{
					id: 2,
					title: 'FF, FH, HH'
				},
				{
					id: 3,
					title: 'FB, HB'
				},
			]
		}
	},
	methods: {
		calc() {
			this.output = {
				description: undefined
			}

			this.$api.getFRC(this.input, (data) => {
				this.output.description = data.string
			})
		},
	}
}
</script>

<style scoped>
.result {
	border: 1px solid #a2a0a0;
	border-radius: 5px;
	text-align: left;
}
</style>