import {createApp} from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router"
import { createAPI } from "./plugins/API"
import ui from "./components/ui";

const api = createAPI()

let app = createApp(App);
app.use(ui)
app.use(store)
app.use(router)
app.use(api)
app.config.globalProperties.$alert = (text, color = 'pink') => {
    console.log(app)
    app.config.globalProperties.$store.state.alert = {
        text,
        color
    }

    setTimeout(() => {
        app.config.globalProperties.$store.state.alert.text = ''
    }, 3000)
}

app.mount("#app")

